export const navItemStyle = {
  general: {
    display: "inline-block",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    cursor: "pointer",
    width: "100%", 
    transitionDuration: ".15s"
  }
};
