// Defines lists of valid HTML attributes (for use in mapPropsToAttrs)

const globalAttrs = [
  "aria-activedescendant",
  "aria-atomic",
  "aria-autocomplete",
  "aria-busy",
  "aria-checked",
  "aria-controls",
  "aria-describedat",
  "aria-describedby",
  "aria-disabled",
  "aria-dropeffect",
  "aria-expanded",
  "aria-flowto",
  "aria-grabbed",
  "aria-haspopup",
  "aria-hidden",
  "aria-invalid",
  "aria-label",
  "aria-labelledby",
  "aria-level",
  "aria-live",
  "aria-multiline",
  "aria-multiselectable",
  "aria-orientation",
  "aria-owns",
  "aria-posinset",
  "aria-pressed",
  "aria-readonly",
  "aria-relevant",
  "aria-required",
  "aria-selected",
  "aria-setsize",
  "aria-sort",
  "aria-valuemax",
  "aria-valuemin",
  "aria-valuenow",
  "aria-valuetext",
  "autoCapitalize",
  "autoComplete",
  "className",
  "contentEditable",
  "contextMenu",
  "dir",
  "draggable",
  "dropzone",
  "enterKeyHint",
  "hidden",
  "id",
  "inputMode",
  "is",
  "itemID",
  "itemProp",
  "itemRef",
  "itemScope",
  "itemType",
  "lang",
  "nonce",
  "slot",
  "spellCheck",
  "tabIndex",
  "title",
  "translate"
];

const eventAttrs = [
  "onAbort",
  "onAfterPrint",
  "onBeforePrint",
  "onBeforeUnload",
  "onBlur",
  "onBlur",
  "onCanPlay",
  "onCanPlayThrough",
  "onChange",
  "onClick",
  "onContextMenu",
  "onCopy",
  "onCueChange",
  "onCut",
  "onDblClick",
  "onDrag",
  "onDragEnd",
  "onDragEnter",
  "onDragLeave",
  "onDragOver",
  "onDragStart",
  "onDrop",
  "onEmptied",
  "onEnded",
  "onError",
  "onError",
  "onError",
  "onfocus",
  "onHashChange",
  "onInput",
  "onInvalid",
  "onKeyDown",
  "onKeyPress",
  "onKeyUp",
  "onLoad",
  "onLoadedData",
  "onLoadedMetadata",
  "onLoadStart",
  "onMessage",
  "onMouseDown",
  "onMouseEnter",
  "onMouseLeave",
  "onMouseMove",
  "onMouseOut",
  "onMouseOver",
  "onMouseUp",
  "onMouseWheel",
  "onOffline",
  "onOnline",
  "onPageHide",
  "onPageShow",
  "onPaste",
  "onPause",
  "onPlay",
  "onPlaying",
  "onPopState",
  "onProgress",
  "onRateChange",
  "onRedo",
  "onReset",
  "onResize",
  "onScroll",
  "onSearch",
  "onSeeked",
  "onSeeking",
  "onSelect",
  "onShow",
  "onStalled",
  "onStorage",
  "onSubmit",
  "onSuspend",
  "onTimeUpdate",
  "onToggle",
  "onUndo",
  "onUnload",
  "onVolumeChange",
  "onWaiting",
  "onWheel"
];

const btnAttrs = [
  "autoFocus",
  "disabled",
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "name",
  "type",
  "value"
];
const imgAttrs = [
  "align",
  "alt",
  "border",
  "crossOrigin",
  "height",
  "hSpace",
  "isMap",
  "longDesc",
  "referrerPolicy",
  "sizes",
  "src",
  "srcSet",
  "useMap",
  "vSpace",
  "width"
];
const formAttrs = [
  "accept",
  "acceptCharset",
  "action",
  "autoComplete",
  "encType",
  "method",
  "name",
  "noValidate",
  "onSubmit",
  "target"
];
const inputAttrs = [
  "accept",
  "align",
  "alt",
  "autoComplete",
  "autoFocus",
  "checked",
  "cols",
  "dirname",
  "disabled",
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "height",
  "list",
  "max",
  "maxLength",
  "min",
  "minLength",
  "multiple",
  "name",
  "pattern",
  "placeholder",
  "readOnly",
  "required",
  "rows",
  "size",
  "src",
  "step",
  "type",
  "value",
  "width",
  "wrap"
];
const anchorAttrs = [
  "charSet",
  "coords",
  "download",
  "href",
  "hrefLang",
  "media",
  "name",
  "ping",
  "referrerPolicy",
  "rel",
  "rev",
  "shape",
  "target",
  "type"
];
export const HTMLGlobalAttributes = new Set(globalAttrs);
export const HTMLEventAttributes = new Set(eventAttrs);
export const buttonAttributes = new Set(btnAttrs);
export const imgAttributes = new Set(imgAttrs);
export const formAttributes = new Set(formAttrs);
export const inputAttributes = new Set(inputAttrs);
export const anchorAttributes = new Set(anchorAttrs);
export default {
  HTMLGlobalAttributes,
  HTMLEventAttributes,
  buttonAttributes,
  imgAttributes,
  formAttributes,
  inputAttributes,
  anchorAttributes
};
